import "./App.css";
import { history } from "./_helpers/history_helper";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import NavBar from "./Components/NavBar/NavBar";
import ErrorFooter from "./Components/Footer/ErrorFooter";
import Overview from "./Components/Overview/Overview";
import Subnets from "./Components/Subnets/Subnets";
import Event from "./Components/EventDetail/Event";
import { Login } from "./Components/login/login";
import PrivateRoute from "./Components/PrivateRoute";
import { ThemeProvider, Stack, Container } from "@mui/material";
import { ConfirmProvider } from "material-ui-confirm";

import theme from "./theme";
import { RecoilRoot } from "recoil";

function App() {
  return (
    <RecoilRoot>
      <ConfirmProvider>
        <ThemeProvider theme={theme}>
          <div className="App">
            <Router history={history}>
              <NavBar />
              <Container sx={{ marginTop: "10px" }} maxWidth="100%">
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={<Navigate replace to="/events" />}
                  />

                  <Route
                    exact
                    path="/login"
                    element={<Login history={history} />}
                  />

                  <Route element={<PrivateRoute />}>
                    <Route path="events/:eventId/*" element={<Event />} />
                    <Route path="events" element={<Overview />}></Route>
                    <Route exact path="/subnets" element={<Subnets />} />
                  </Route>
                </Routes>
              </Container>
              <ErrorFooter />
            </Router>
          </div>
        </ThemeProvider>
      </ConfirmProvider>
    </RecoilRoot>
  );
}

export default App;
