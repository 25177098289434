import {
  Button,
  Box,
  Grid,
  Stack,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  Typography,
  Container,
} from "@mui/material";
import AlarmPopup from "../Footer/alarm_popup";
import React from "react";
import { useRecoilState } from "recoil";

import { useState } from "react";
import SubnetActions from "../../API/subnets";
import { apiErrorPostSubnetAtom } from "../../State/errors";
import VlanActions from "../../API/vlan";

import { apiErrorPostVlanAtom } from "../../State/errors";

export default function AddVlanDialog(props) {
  const { handleCloseDialog, openDialog, eventId } = props;
  const vlanActions = VlanActions();

  const [vlanId, setVlanId] = useState("");
  const [name, setName] = useState("");
  const [subnet, setSubnet] = useState("");
  const [mask, setMask] = useState("");
  const [ignoreSubnetFilter, setIgnoreSUbnetFilter] = useState(false);

  const [apiErrorPostVlan, setApiErrorPostVlan] =
    useRecoilState(apiErrorPostVlanAtom);

  const sx_texfield_color = {
    marginRight: 5,
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        borderColor: "white",
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      "& > fieldset": {
        borderColor: "orange",
      },
    },
  };

  const onCancel = () => {
    handleCloseDialog();
  };

  const onAddVlan = () => {
    const body = {
      eventId: eventId,
      vlanId: vlanId,
      name: name,
      subnet: subnet,
      subnetMask: mask,
    };

    vlanActions.createVlan(body, ignoreSubnetFilter).then((result) => {
      if (result) {
        vlanActions.getVlanList(eventId).then();
        handleCloseDialog();
      }
    });
  };

  const handleVlanIDChange = (event) => {
    setVlanId(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSubnetChange = (event) => {
    setSubnet(event.target.value);
  };

  const handleMaskChange = (event) => {
    setMask(event.target.value);
  };

  const handleSubnetFilterCheckChange = (event) => {
    setIgnoreSUbnetFilter(event.target.value);
  };

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>Add Vlan</DialogTitle>
      <DialogContent>
        <Container>
          <Grid fullWidth container spacing={2} direction="column">
            <Grid item>
              <Grid marginTop={2}>
                <TextField
                  label="VlanID"
                  type="number"
                  defaultValue="0"
                  onChange={handleVlanIDChange}
                  color="primary"
                  sx={sx_texfield_color}
                />
                <TextField
                  label="Name"
                  type="text"
                  onChange={handleNameChange}
                  sx={sx_texfield_color}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Grid>
                <TextField
                  label="Subnet"
                  type="text"
                  defaultValue=""
                  onChange={handleSubnetChange}
                  color="primary"
                  sx={sx_texfield_color}
                />
                <TextField
                  label="Subnet-Mask"
                  type="text"
                  onChange={handleMaskChange}
                  sx={sx_texfield_color}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Stack direction="row" alignItems="center">
                <Typography>Ignore Subnet Filter</Typography>
                <Checkbox onClick={handleSubnetFilterCheckChange} />
              </Stack>
            </Grid>
          </Grid>
        </Container>
        {apiErrorPostVlan ? (
          <Box marginTop={1} marginBottom={1}>
            <AlarmPopup
              name="fetchError-create Vlan"
              atomValue={apiErrorPostVlan}
              setAtomValue={setApiErrorPostVlan}
              message={"Error create Vlan "}
            />
          </Box>
        ) : (
          <Box />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onAddVlan}>Add Vlan</Button>
      </DialogActions>
    </Dialog>
  );
}
