import { createTheme } from "@mui/material/styles";

const themeJsonForms = createTheme({
  palette: {
    common: {
      white: "#FFF",
      black: "#000",
      highlight: "#DDDDDD",
    },
    primary: {
      main: "#FFFFFF",
      light: "#FFFFFF",
      dark: "#FFFFFF",
      contrastText: "#000000",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#AAAAAA",
      disabled: "DDDDDD",
    },
    secondary: {
      main: "#DDD",
    },
    overrides: {},

    background: {
      paper: "#464646",
    },
  },
  //shadows: ["none", `0px 10px 10px #000000`, `0px 10px 10px #FFFFFF`],
});

export default themeJsonForms;
