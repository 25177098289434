import { atom } from "recoil";

export const loginErrorAtom = atom({
  key: "loginErrorAtom",
  default: null,
});

export const fetchErrorEventListAtom = atom({
  key: "fetchErrorEventList",
  default: null,
});

export const fetchErrorEventAtom = atom({
  key: "fetchErrorEvent",
  default: null,
});

export const apiErrorPostEventAtom = atom({
  key: "apiErrorPostEventAtom",
  default: null,
});

export const apiErrorDeleteEventAtom = atom({
  key: "apiErrorDeleteEventAtom",
  default: null,
});

export const fetchErrorSubnetListAtom = atom({
  key: "fetchErrorSubnetListAtom",
  default: null,
});

export const apiErrorPostSubnetAtom = atom({
  key: "apiErrorPostSubnetAtom",
  default: null,
});

//Vlans
export const fetchErrorVlanListAtom = atom({
  key: "fetchErrorVlanList",
  default: null,
});

export const fetchErrorVlanAtom = atom({
  key: "fetchErrorVlan",
  default: null,
});

export const apiErrorPostVlanAtom = atom({
  key: "apiErrorPostVlan",
  default: null,
});

export const apiErrorDeleteVlanAtom = atom({
  key: "apiErrorDeleteVlan",
  default: null,
});

//Clients
export const fetchErrorClientListAtom = atom({
  key: "fetchErrorClientList",
  default: null,
});

export const fetchErrorClientAtom = atom({
  key: "fetchErrorClient",
  default: null,
});

export const apiErrorPostClientAtom = atom({
  key: "apiErrorPostClient",
  default: null,
});
