import {
  Button,
  IconButton,
  Grid,
  Container,
  Typography,
  Box,
  Card,
  Tab,
  Tabs,
} from "@mui/material";
import {
  CircularIndeterminate,
  CircularIndeterminateWithTimeout,
} from "../../_helpers/loading-Icon";
import { useEffect, useState } from "react";
import { Routes, Route, Link, Outlet } from "react-router-dom";

import { useRecoilState } from "recoil";
import { vlanListEventAtom } from "../../State/atoms";

import VlanActions from "../../API/vlan";
import VlanList from "./Vlan_List";
import VlanDetail from "./VlanDetail";

export function TabPanelVlan(props) {
  const { children, activeTab, index, eventIn, ...other } = props;

  const vlanActions = VlanActions();

  const [eventVLANs] = useRecoilState(vlanListEventAtom);

  useEffect(() => {
    console.log(eventIn);
    vlanActions.getVlanList(eventIn.id).then();
  }, [eventIn]);

  return (
    <div
      role="tabpanel"
      hidden={activeTab !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {activeTab === index && (
        <Box sx={{ height: "78vh" }}>
          <Grid container height="100%" spacing={2}>
            <Grid item xs={3} xm={2}>
              <Typography variant="h4" marginTop={4} marginBottom={2}>
                Vlans
              </Typography>
              <VlanList vlans={eventVLANs} eventId={eventIn.id}></VlanList>
            </Grid>

            <Grid item xs={9} xm={10} marginTop={"10px"}>
              {eventVLANs.length == 0 ? (
                <CircularIndeterminateWithTimeout timeout={2000} />
              ) : (
                <>
                  <Grid
                    container
                    justifyContent="flex-end"
                    marginBottom={2}
                  ></Grid>
                  <Routes>
                    <Route
                      path=":vlanId/*"
                      element={<VlanDetail event={eventIn} />}
                    ></Route>
                  </Routes>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
}
