import {
  Button,
  Box,
  Grid,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Stack,
  Container,
} from "@mui/material";
import AlarmPopup from "../Footer/alarm_popup";

import React from "react";

import { useState } from "react";
import EventActions from "../../API/events";
import SubnetActions from "../../API/subnets";
import { useRecoilState } from "recoil";
import { apiErrorPostEventAtom } from "../../State/errors";

export default function AddEventDialog(props) {
  const { handleCloseDialog, openDialog } = props;
  const eventActions = EventActions();
  const subnetActions = SubnetActions();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [tecContact, setTecContact] = useState("");
  const [dateStart, setDateStart] = useState("1900-01-01");
  const [dateEnd, setDateEnd] = useState("2999-01-01");
  const [subnetList, setSubnetList] = useState([]);
  const [subnet, setSubnet] = useState("");
  const [numberSwitches, setNumberSwitches] = useState(0);
  const [noVlan1, setNoVlan1] = useState(0);
  const [comment, setComment] = useState("");

  const [apiErrorPostEvent, setApiErrorPostEvent] = useRecoilState(
    apiErrorPostEventAtom
  );

  const sx_texfield_color = {
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        borderColor: "white",
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      "& > fieldset": {
        borderColor: "orange",
      },
    },
  };

  const onCancel = () => {
    handleCloseDialog();
  };

  const onAddEvent = () => {
    const body = {
      name: name,
      description: description,
      technicalContact: tecContact,
      dateStart: dateStart,
      dateEnd: dateEnd,
      eventSubnet: subnet.id,
      numberSwitches: numberSwitches,
      noVlan1: noVlan1 ? 1 : 0,
      comment: comment,
    };
    eventActions.createEvent(body).then((result) => {
      if (result) {
        handleCloseDialog();
      }
    });
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleTecContactChange = (event) => {
    setTecContact(event.target.value);
  };

  const handleDateStartChange = (event) => {
    setDateStart(event.target.value);
    subnetActions.getUnusedSubnetList(dateStart, dateEnd).then((subnetList) => {
      if (subnetList != null) {
        setSubnetList(subnetList);
      }
    });
  };

  /*  const handleDateEndChange = (event) => {
    setDateEnd(event.target.value);
    subnetActions.getUnusedSubnetList(dateStart, dateEnd).then((subnetList) => {
      if (subnetList != null) {
        setSubnetList(subnetList);
      }
    });
  };*/

  const handleSubnetChange = (event) => {
    setSubnet(event.target.value);
  };

  const handleNoSwitchesChange = (event) => {
    setNumberSwitches(event.target.value);
  };

  const handleNoVlan1Change = (event) => {
    setNoVlan1(event.target.value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>Add Event</DialogTitle>
      <DialogContent>
        <Container>
          <Grid
            fullWidth
            container
            spacing={2}
            justifyContent="space-between"
            direction="column"
          >
            <Grid item marginTop={2}>
              <Stack direction="row" spacing={2}>
                <TextField
                  label="Name"
                  type="text"
                  defaultValue=""
                  onChange={handleNameChange}
                  color="primary"
                  sx={sx_texfield_color}
                />
                <TextField
                  label="Technical Contact"
                  type="text"
                  onChange={handleTecContactChange}
                  sx={sx_texfield_color}
                />
              </Stack>
            </Grid>
            <Grid item fullWidth>
              <TextField
                fullWidth
                label="Description"
                type="text"
                onChange={handleDescriptionChange}
                sx={sx_texfield_color}
              />
            </Grid>

            <Grid item>
              <Box width="100%">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box>
                    <Typography>Event Start Date </Typography>
                    <TextField
                      type="date"
                      onChange={handleDateStartChange}
                      sx={sx_texfield_color}
                    />
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <FormControl fullWidth sx={sx_texfield_color}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ color: "#FFF" }}
                >
                  Subnet
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subnet}
                  label="Subnet"
                  onChange={handleSubnetChange}
                >
                  {subnetList.map((subnet) => (
                    <MenuItem value={subnet} key={subnet.id}>
                      {subnet.subnet} / {subnet.subnetMask}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <TextField
                  label="Number Switches"
                  type="number"
                  defaultValue="1"
                  onChange={handleNoSwitchesChange}
                  sx={sx_texfield_color}
                />
                <Box>
                  <Stack direction="row" alignItems="center">
                    <Typography>NoVlan1</Typography>
                    <Checkbox onClick={handleNoVlan1Change} />
                  </Stack>
                </Box>
              </Stack>
            </Grid>

            <Grid item>
              <TextField
                fullWidth
                label="Comment"
                type="text"
                onChange={handleCommentChange}
                multiline
                sx={sx_texfield_color}
              />
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Box>
              {apiErrorPostEvent ? (
                <Box marginTop={1} marginBottom={1}>
                  <AlarmPopup
                    name="fetchError-create Event"
                    atomValue={apiErrorPostEvent}
                    setAtomValue={setApiErrorPostEvent}
                    message={"Error create Event "}
                  />
                </Box>
              ) : (
                <Box />
              )}
            </Box>
          </Grid>
          <Grid item>
            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  sx={{ marginRight: 4 }}
                  variant="outlined"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={onAddEvent}>
                  Add Event
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
