import {
  Button,
  Grid,
  Container,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { eventListAtom } from "../../State/atoms";
import EventActions from "../../API/events";
import { useUserActions } from "../../API/user.actions";

import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EventTable from "./EventsTable";
import AddEventDialog from "./addEventDialog";

function Overview() {
  const [eventList, setEventList] = useRecoilState(eventListAtom);
  const [openDialog, setOpenDialog] = useState(false);
  const eventActions = EventActions();

  const userActions = useUserActions();

  useEffect(() => {
    userActions.getCurrentUser();
  }, []);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    eventActions.getEventList();
  };

  return (
    <Container name="Overview" justify="center" maxWidth="100%">
      <Typography variant="h4" marginTop={4} marginBottom={5}>
        Events
      </Typography>
      <Grid container justifyContent="flex-end" marginBottom={2}>
        <Button
          startIcon={<AddCircleIcon />}
          variant="Outlined"
          sx={{ backgroundColor: "#444" }}
          onClick={handleClickOpenDialog}
        >
          Add Event
        </Button>

        <AddEventDialog
          handleCloseDialog={handleCloseDialog}
          openDialog={openDialog}
        />
      </Grid>
      <EventTable />
    </Container>
  );
}

export default Overview;
