import { useSetRecoilState } from "recoil";
import { useFetchWrapper } from "../_helpers/fetch-wrapper";
import { subnetListAtom } from "../State/atoms";
import { API_URL } from "../config.js";
import {
  apiErrorPostSubnetAtom,
  fetchErrorSubnetListAtom,
  apiErrorPostEventAtom,
} from "../State/errors";
export default function SubnetActions() {
  //const baseUrl = `${process.env.REACT_APP_API_URL}/users`;
  const baseUrl = API_URL + "/subnets";

  const setSubnetList = useSetRecoilState(subnetListAtom);
  const setFetchErrorSubnetList = useSetRecoilState(fetchErrorSubnetListAtom);
  const setApiErrorPostSubnet = useSetRecoilState(apiErrorPostSubnetAtom);

  const fetchWrapper = useFetchWrapper();

  return {
    getUnusedSubnetList,
    getSubnets,
    createSubnet,
  };

  function getUnusedSubnetList(dateStart, dateEnd, eventId) {
    const body = {
      dateStart: dateStart,
      dateEnd: dateEnd,
      eventId: eventId,
    };
    return fetchWrapper
      .put(`${baseUrl}/unused`, body)
      .then()
      .catch((err) => {
        console.log(err);
        setFetchErrorSubnetList(err.message);
      });
  }

  function getSubnets() {
    return fetchWrapper
      .get(`${baseUrl}/list`)
      .then(setSubnetList)
      .catch((err) => {
        console.log(err);
        setFetchErrorSubnetList(err.message);
      });
  }

  function createSubnet(subnet) {
    console.log(`Post Subnet: ${subnet.subnet}`);
    return fetchWrapper.post(`${baseUrl}/create`, subnet).catch((err) => {
      console.log(err);
      setApiErrorPostSubnet(err);
    });
  }
}

//throw new Error(err.message);
