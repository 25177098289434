import { useSetRecoilState } from "recoil";
import { useFetchWrapper } from "../_helpers/fetch-wrapper";
import { vlanListEventAtom } from "../State/atoms";
import { API_URL } from "../config.js";

import {
  fetchErrorVlanListAtom,
  fetchErrorVlanAtom,
  apiErrorPostVlanAtom,
  apiErrorDeleteVlanAtom,
} from "../State/errors";

export default function VlanActions() {
  //const baseUrl = `${process.env.REACT_APP_API_URL}/users`;
  const baseUrl = API_URL + "/vlans";

  const setVlanList = useSetRecoilState(vlanListEventAtom);

  const setFetchErrorVlanList = useSetRecoilState(fetchErrorVlanListAtom);
  const setFetchErrorVlan = useSetRecoilState(fetchErrorVlanAtom);
  const setApiErrorPostVlan = useSetRecoilState(apiErrorPostVlanAtom);
  const setApiErrorDeleteVlan = useSetRecoilState(apiErrorDeleteVlanAtom);

  const fetchWrapper = useFetchWrapper();

  return {
    getVlanList,
    getVlan,
    createVlan,
    updateVlan,
    deleteVlan,
  };

  function getVlanList(eventId) {
    return fetchWrapper
      .get(`${baseUrl}/list/${eventId}`)
      .then(setVlanList)
      .catch((err) => {
        console.log(err);
        setFetchErrorVlanList(err.message);
      });
  }

  function getVlan(vlanId) {
    return fetchWrapper
      .get(`${baseUrl}/${vlanId}`)
      .then()
      .catch((err) => {
        console.log(err);
        setFetchErrorVlan(err.message);
      });
  }

  function createVlan(vlan, ignoreSubnetFilter) {
    console.log(`Post Vlan create with name: ${vlan.name}`);
    return fetchWrapper
      .post(
        `${baseUrl}/create?ignore_subnet_filter=${ignoreSubnetFilter}`,
        vlan
      )
      .catch((err) => {
        setApiErrorPostVlan(err);
      });
  }

  function updateVlan(vlan) {
    console.log(`Post Vlan update with name: ${vlan.name}`);
    return fetchWrapper.post(`${baseUrl}/update`, vlan).catch((err) => {
      setApiErrorPostVlan(err);
    });
  }

  function deleteVlan(vlanUID) {
    console.log(`Delete Vlan ${vlanUID}`);
    return fetchWrapper
      .delete(`${baseUrl}/${vlanUID}`)
      .then()
      .catch((err) => {
        setApiErrorDeleteVlan(err);
      });
  }
}

//throw new Error(err.message);
