import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { jwtTokenAtom } from "../State/auth";

export default function PrivateRoute({ children }) {
  const token = useRecoilValue(jwtTokenAtom);

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return token ? <Outlet /> : <Navigate to="/login" />;
}
