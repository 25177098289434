import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";

export function CircularIndeterminate() {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      marginTop={"20px"}
    >
      <Typography marginRight={"20px"}> Loading ...</Typography>
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    </Box>
  );
}

export function CircularIndeterminateWithTimeout(props) {
  const timeout = props.timeout ?? 2000;

  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, timeout);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      marginTop={"20px"}
    >
      {showLoading ? (
        <>
          <Typography marginRight={"20px"}> Loading ...</Typography>
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Typography marginRight={"20px"}> No Content</Typography>
        </>
      )}
    </Box>
  );
}
