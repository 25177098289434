import * as React from "react";
import { Button, Grid, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddSubnetDialog from "./addSubnetDialog";
import SubnetsTable from "./SubnetsTable";

import CircularIndeterminate from "../../_helpers/loading-Icon";

export default function Subnets() {
  useEffect(() => {}, []);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Container justify="center" maxWidth="100%">
      <Typography variant="h4" marginTop={4} marginBottom={5}>
        Subnets
      </Typography>
      <Grid container justifyContent="flex-end" marginBottom={2}>
        <Button
          startIcon={<AddCircleIcon />}
          variant="Outlined"
          sx={{ backgroundColor: "#444" }}
          onClick={handleClickOpenDialog}
        >
          Add Subnet
        </Button>

        <AddSubnetDialog
          handleCloseDialog={handleCloseDialog}
          openDialog={openDialog}
        />
      </Grid>
      <SubnetsTable />
    </Container>
  );
}
