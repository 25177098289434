import { useSetRecoilState } from "recoil";

import { history } from "../_helpers/history_helper";
import { useFetchWrapper } from "../_helpers/fetch-wrapper";
import { API_URL } from "../config.js";

import { jwtTokenAtom, currentUserAtom } from "../State/auth";
import { usersAtom } from "../State/users";
import { UserLogin } from "../model/user";
import { loginErrorAtom } from "../State/errors";

export { useUserActions };

function useUserActions() {
  const baseUrl = API_URL;
  const fetchWrapper = useFetchWrapper();
  const setToken = useSetRecoilState(jwtTokenAtom);
  const setCurrentUser = useSetRecoilState(currentUserAtom);
  const setUsers = useSetRecoilState(usersAtom);
  const setApiErrorPostEvent = useSetRecoilState(loginErrorAtom);

  return {
    login,
    logout,
    getAll,
    getCurrentUser,
  };

  function login(username, password) {
    let user = new UserLogin(username, password);

    return fetchWrapper
      .post(`${baseUrl}/token`, user)
      .then((response) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("token", response.access_token);
        setToken(response.access_token);
        // get return url from location state or default to home page
        const { from } = history.location.state || {
          from: { pathname: "/events" },
        };
        history.push(from);
      })
      .catch((error) => {
        setApiErrorPostEvent(error);
        console.log(error);
        return Promise.reject(error);
      });
  }

  function getCurrentUser() {
    return fetchWrapper.get(`${baseUrl}/users/me/`).then((user) => {
      console.log(user);
      setCurrentUser(user);
    });
  }

  function logout() {
    // remove user from local storage, set auth state to null and redirect to login page
    localStorage.removeItem("token");
    setToken(null);
    setCurrentUser(null);
    history.push("/login");
  }

  function getAll() {
    return fetchWrapper.get(baseUrl).then(setUsers);
  }
}
