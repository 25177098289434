import * as React from "react";
import {
  Box,
  Button,
  Grid,
  TableContainer,
  Collapse,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import EventActions from "../../API/events";
import VlanActions from "../../API/vlan";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { eventListAtom } from "../../State/atoms";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNavigate } from "react-router-dom";

export default function EventTable() {
  const [eventList, setEventList] = useRecoilState(eventListAtom);
  const [rowExpanded, setRowExpanded] = useState(false);
  const eventActions = EventActions();
  const vlanActions = VlanActions();

  useEffect(() => {
    eventActions.getEventList();
  }, []);

  const passedSetRowExpanded = (e, value) => {
    e.preventDefault();
    setRowExpanded(value);
    console.log(value);
  };

  function VLANSubtable(props) {
    const { event } = props;
    const [eventVLANs, setEventVLANs] = useState([]);

    useEffect(() => {
      vlanActions.getVlanList(event.id).then(setEventVLANs);
    }, []);

    return (
      <Table
        elevation={10}
        sx={{
          backgroundColor: "#3C3C3C",
        }}
      >
        <TableHead sx={{ backgroundColor: "#333" }}>
          <TableRow sx={{ "& > *": { borderBottomColor: "#000" } }}>
            <TableCell>ID </TableCell>
            <TableCell>Name </TableCell>
            <TableCell>subnet</TableCell>
            <TableCell>taggedPorts</TableCell>
            <TableCell>untaggedPorts</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eventVLANs.map((vlan) => (
            <TableRow hover key={vlan.id} sx={{ "& > *": { border: 0 } }}>
              <TableCell>{vlan.id}</TableCell>
              <TableCell>{vlan.name}</TableCell>
              <TableCell>{vlan.subnet}</TableCell>
              <TableCell>{vlan.taggedPorts}</TableCell>
              <TableCell>{vlan.untaggedPorts}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  function CustomTableHeader() {
    return (
      <TableHead style={{ backgroundColor: "#111" }}>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell align="right">Event</TableCell>
          <TableCell align="right">Description</TableCell>
          <TableCell align="right">Event-Subnet</TableCell>
          <TableCell align="right">Date Start</TableCell>
          <TableCell align="right">Date End</TableCell>
          <TableCell align="right">technical&nbsp;Contact</TableCell>
          <TableCell align="right">Comment</TableCell>
          <TableCell align="right">Edited By</TableCell>
          <TableCell align="right">Edited On</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function CollapsableRow(props) {
    const { event } = props;
    let navigate = useNavigate();

    return (
      <React.Fragment>
        <TableRow
          hover
          key={event.id}
          sx={{ "& > *": { border: 0 } }}
          onClick={() => navigate(`/events/${event.id}`)}
        >
          <TableCell>{event.id}</TableCell>
          <TableCell align="right">{event.name}</TableCell>
          <TableCell align="right">{event.description}</TableCell>
          <TableCell align="right">
            {event.subnet}/{event.subnetMask}
          </TableCell>
          <TableCell align="right">{event.dateStart}</TableCell>
          <TableCell align="right">
            {event.dateEnd === "2999-01-01" ? "" : event.dateEnd}
          </TableCell>
          <TableCell align="right">{event.technicalContact}</TableCell>
          <TableCell align="right">{event.comment}</TableCell>
          <TableCell align="right">{event.editedBy}</TableCell>
          <TableCell align="right">{event.editedOn}</TableCell>
        </TableRow>
        <TableRow sx={{ "& > *": { borderBottomColor: "#000" } }}></TableRow>
      </React.Fragment>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <CustomTableHeader></CustomTableHeader>
        <TableBody>
          {eventList.map((event) => (
            <CollapsableRow
              key={event.id}
              event={event}
              setRowExpanded={passedSetRowExpanded}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
