import { useSetRecoilState } from "recoil";
import { useFetchWrapper } from "../_helpers/fetch-wrapper";
import { API_URL } from "../config.js";

import { clientListAtom } from "../State/atoms";
import {
  fetchErrorClientListAtom,
  fetchErrorClientAtom,
  apiErrorPostClientAtom,
} from "../State/errors";

export default function ClientActions() {
  //const baseUrl = `${process.env.REACT_APP_API_URL}/users`;
  const baseUrl = API_URL + "/clients";

  const setClientList = useSetRecoilState(clientListAtom);

  const setFetchErrorClientList = useSetRecoilState(fetchErrorClientListAtom);
  const setFetchErrorClient = useSetRecoilState(fetchErrorClientAtom);
  const setApiErrorPostClient = useSetRecoilState(apiErrorPostClientAtom);
  const fetchWrapper = useFetchWrapper();

  return {
    getClientList,
    getClient,
    createClients,
    updateClients,
    deleteClients,
  };

  function getClientList(eventId, vlanId) {
    return fetchWrapper
      .get(`${baseUrl}/list?eventId=${eventId}&vlanId=${vlanId}`)
      .then((clients) => {
        setClientList(clients);
        console.log(clients);
      })
      .catch((err) => {
        console.log(err);
        setFetchErrorClientList(err.message);
      });
  }

  function getClient(clientId) {
    return fetchWrapper
      .get(`${baseUrl}/${clientId}`)
      .then()
      .catch((err) => {
        console.log(err);
        setFetchErrorClient(err.message);
      });
  }

  function createClients(clients) {
    console.log(`Post Client create with client: ${clients}`);
    return fetchWrapper
      .post(`${baseUrl}/create`, clients)
      .then((result) => {
        return result;
      })
      .catch((err) => {
        setApiErrorPostClient(err);
      });
  }

  function updateClients(clients) {
    console.log(`Post Client update with client: ${clients}`);
    return fetchWrapper
      .post(`${baseUrl}/update`, clients)
      .then((result) => {
        return result;
      })
      .catch((err) => {
        setApiErrorPostClient(err);
      });
  }

  function deleteClients(clients) {
    console.log(`Post Client delete with client: ${clients}`);
    return fetchWrapper
      .post(`${baseUrl}/delete`, clients)
      .then((result) => {
        return result;
      })
      .catch((err) => {
        setApiErrorPostClient(err);
      });
  }
}

//throw new Error(err.message);
