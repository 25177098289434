import logo from "./logo.svg";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

import { useRecoilValue } from "recoil";

import { currentUserAtom, jwtTokenAtom } from "../../State/auth";
import { useUserActions } from "../../API/user.actions";

const useStyles = makeStyles((theme) => ({
  AppBar: {
    backgroundColor: "#464646",
    borderRadius: "4px",
    boxShadow: "0px 5px 10px black",
  },
  navlinks: {
    marginLeft: theme.spacing(),
    display: "flex",
  },
  logo: {
    marginTop: "10px",
    flexGrow: "1",
    cursor: "pointer",
    height: "40px",
  },
  link: {
    textDecoration: "none",

    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(5),
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
}));

export default function NavBar(props) {
  const classes = useStyles();
  const token = useRecoilValue(jwtTokenAtom);
  const currentUser = useRecoilValue(currentUserAtom);

  const userActions = useUserActions();

  const isUserAdmin = () => {
    if (currentUser?.access_rights && currentUser.access_rights >= 2) {
      console.log(currentUser.access_rights);
      return true;
    }
    return false;
  };

  return (
    <AppBar position="sticky">
      <Toolbar className={classes.AppBar}>
        <Link to="/">
          <img src={logo} className={classes.logo}></img>
        </Link>
        {token ? (
          <Typography>
            <div className={classes.navlinks}>
              <Link to="/" className={classes.link}>
                Events
              </Link>
              <Link to="/subnets" className={classes.link}>
                Subnets
              </Link>
              <Link
                to="/events"
                onClick={userActions.logout}
                className={classes.link}
              >
                Logout
              </Link>
            </div>
          </Typography>
        ) : (
          <div />
        )}
      </Toolbar>
    </AppBar>
  );
}
