import { Box, Alert, Container, Collapse, Stack } from "@mui/material";

function AlarmPopup(props) {
  const message = props.message;
  let atomValue = props.atomValue;

  if (atomValue) {
    return (
      <Container>
        <Collapse in={atomValue ? true : false}>
          <Alert
            severity="error"
            onClose={() => {
              props.setAtomValue(null);
            }}
          >
            {message}: {atomValue}
          </Alert>
        </Collapse>
      </Container>
    );
  } else {
    return null;
  }
}

export default AlarmPopup;
