import * as React from "react";
import {
  Button,
  IconButton,
  Grid,
  Container,
  Typography,
  Box,
  Card,
  Tab,
  Tabs,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Router, useParams } from "react-router-dom";
import { Routes, Route, Link, Outlet } from "react-router-dom";
import { useRecoilState } from "recoil";
import { vlanListEventAtom } from "../../State/atoms";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { useNavigate } from "react-router-dom";

import EventActions from "../../API/events";
import { TabPanelVlan } from "./Tab_Panel_Event_Vlan";
import { TabPanelDetail } from "./Tab_Panel_Event_Detail";

import {
  CircularIndeterminate,
  CircularIndeterminateWithTimeout,
} from "../../_helpers/loading-Icon";

export default function Event() {
  let { eventId } = useParams();
  const eventActions = EventActions();
  const navigate = useNavigate();

  const [event, setEvent] = useState();
  const [eventVLANs] = useRecoilState(vlanListEventAtom);

  const [activeTab, setActiveTab] = React.useState(0);

  useEffect(() => {
    eventActions.getEvent(eventId).then(setEvent);
  }, [eventId]);

  const handleBack = (e) => {
    e.preventDefault();
    navigate("/events");
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <>
      <Card
        elevation={10}
        sx={{ backgroundColor: "#333", overflowY: "scroll" }}
        maxWidth="100%"
      >
        <Container justify="center" marginBottom={2} maxWidth="100%">
          {!event ? (
            <CircularIndeterminate />
          ) : (
            <>
              <Stack
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                marginTop={2}
                marginBottom={1}
              >
                <IconButton
                  aria-label="back"
                  color="primary"
                  size="large"
                  onClick={handleBack}
                >
                  <ArrowCircleLeftIcon fontSize="inherit" />
                </IconButton>

                <Typography variant="h4">
                  {event?.name} - {event.subnet}/{event.subnetMask}
                </Typography>
                <Typography></Typography>
              </Stack>

              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={activeTab}
                  activeTab={activeTab}
                  onChange={handleTabChange}
                  aria-label="Event Tabs"
                  centered
                >
                  <Tab label="Details" variant="outlined" {...a11yProps(0)} />
                  <Tab
                    label="Vlans"
                    variant="outlined"
                    value={1}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
              <TabPanelDetail activeTab={activeTab} eventIn={event} index={0}>
                Item One
              </TabPanelDetail>
              <TabPanelVlan activeTab={activeTab} eventIn={event} index={1}>
                Item Two
              </TabPanelVlan>
            </>
          )}
        </Container>
      </Card>
      <Outlet />
    </>
  );
}
