import { useRecoilState } from "recoil";

import { history } from "./history_helper";
import { jwtTokenAtom, currentUserAtom } from "../State/auth";

import { API_URL } from "../config.js";

export { useFetchWrapper };

function useFetchWrapper() {
  const [token, setToken] = useRecoilState(jwtTokenAtom);
  const [currentUser, setCurrentUser] = useRecoilState(currentUserAtom);

  return {
    get: request("GET"),
    post: request("POST"),
    put: request("PUT"),
    delete: request("DELETE"),
  };

  function request(method) {
    return (url, body) => {
      const requestOptions = {
        method,
        headers: authHeader(url),
      };
      if (body) {
        if (
          body.type === "application/x-gzip" ||
          body.type === "application/gzip"
        ) {
          const formData = new FormData();
          formData.append("file", body);
          requestOptions.body = formData;
        } else if (body.type === "UserLogin") {
          requestOptions.body = `grant_type=&username=${body.name}&password=${body.password}&scope=&client_id=&client_secret=`;
          requestOptions.headers["Content-Type"] =
            "application/x-www-form-urlencoded";
        } else {
          requestOptions.headers["Content-Type"] = "application/json";
          requestOptions.body = JSON.stringify(body);
        }
      }
      //console.log(requestOptions);
      return fetch(url, requestOptions).then(handleResponse);
    };
  }

  // helper functions

  function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const isLoggedIn = !!token;
    const isApiUrl = url.startsWith(API_URL);
    if (isLoggedIn && isApiUrl) {
      return { Authorization: `Bearer ${token}` };
    } else {
      return {};
    }
  }

  function handleResponse(response) {
    return response.text().then((text) => {
      try {
        const data = text && JSON.parse(text);

        if (!response.ok) {
          if ([401, 403].includes(response.status) && token) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            localStorage.removeItem("token");
            localStorage.removeItem("currentUser");
            setToken(null);
            setCurrentUser(null);
            history.push("/login");
          }
          const error =
            (data && data.message && data.detail) ||
            response.statusText + " " + data?.detail;
          return Promise.reject(error);
        }

        return data;
      } catch {
        console.log(response);
        const error = response.statusText;
        return Promise.reject(error);
      }
    });
  }
}
