import { atom } from "recoil";

export const eventListAtom = atom({
  key: "eventList",
  default: [],
});

export const subnetListAtom = atom({
  key: "subnetList",
  default: [],
});

export const vlanListEventAtom = atom({
  key: "vlanListEvent",
  default: [],
});

export const clientListAtom = atom({
  key: "clientList",
  default: [],
});
