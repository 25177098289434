import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import AddVlanDialog from "./addVlanDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useEffect, useState } from "react";

import * as React from "react";
import {
  Card,
  Button,
  Divider,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  Box,
  ThemeProvider,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  box: {
    "&:hover": {
      color: "white",
      borderBottom: "1px solid white",
      backgroundColor: "black",
    },
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "14px",
    //    marginLeft: theme.spacing(5),
    "&:hover": {
      color: "white",
      //borderBottom: "1px solid white",
    },
  },
}));

export default function VlanList(props) {
  const vlans = props.vlans;
  const eventId = props.eventId;
  const classes = useStyles();

  const [selectedIndex, setSelectedIndex] = React.useState();
  const handleListItemClick = (e, index) => {
    setSelectedIndex(index);
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Card sx={{ width: "100%" }} elevation={10} color="primary">
      <List>
        {vlans.map((vlan, index) => (
          <Link
            key={vlan.id}
            to={`/events/${eventId}/${vlan.id}`}
            className={classes.link}
          >
            <ListItemButton
              selected={selectedIndex === index}
              onClick={(e) => handleListItemClick(e, index)}
            >
              <ListItemText primary={vlan.vlanId + " - " + vlan.name} />
            </ListItemButton>
          </Link>
        ))}
        <Divider
          fullWidth
          sx={{
            marginTop: "10px",
            marginBottom: "2px",
            backgroundColor: "#FFF",
          }}
        />
        <Button
          fullWidth
          startIcon={<AddCircleIcon />}
          variant="Outlined"
          sx={{ backgroundColor: "#333" }}
          onClick={handleClickOpenDialog}
        >
          Add Vlan
        </Button>
        <AddVlanDialog
          handleCloseDialog={handleCloseDialog}
          openDialog={openDialog}
          eventId={eventId}
        />
      </List>
    </Card>
  );
}
