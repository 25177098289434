import {
  Button,
  Box,
  Card,
  Grid,
  Container,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import EventActions from "../../API/events";
import SubnetActions from "../../API/subnets";
import { useRecoilValue, useRecoilState } from "recoil";
import { apiErrorPostEventAtom } from "../../State/errors";
import { currentUserAtom } from "../../State/auth";
import AlarmPopup from "../Footer/alarm_popup";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";

export function TabPanelDetail(props) {
  const { children, activeTab, index, eventIn, ...other } = props;

  const eventActions = EventActions();
  const subnetActions = SubnetActions();

  const currentUser = useRecoilValue(currentUserAtom);

  const [event, setEvent] = useState(eventIn);
  const [eventCopy, setEventCopy] = useState(eventIn);

  const [dateStart, setDateStart] = useState("1900-01-01");
  const [dateEnd, setDateEnd] = useState("2999-01-01");
  const [subnetList, setSubnetList] = useState([]);

  const navigate = useNavigate();

  const [apiErrorPostEvent, setApiErrorPostEvent] = useRecoilState(
    apiErrorPostEventAtom
  );

  const confirm = useConfirm();

  useEffect(() => {
    setEvent(eventIn);
  }, [eventIn]);

  useEffect(() => {
    subnetActions
      .getUnusedSubnetList(dateStart, dateEnd, event.id)
      .then((subnetList) => {
        if (subnetList != null) {
          setSubnetList(subnetList);
        }
      });
  }, [event]);

  const onCancel = () => {
    console.log("cancel");
    setEventCopy(event);
  };

  const onUpdateEvent = () => {
    const body = {
      id: eventCopy.id,
      name: eventCopy.name,
      description: eventCopy.description,
      technicalContact: eventCopy.technicalContact,
      dateStart: dateStart,
      dateEnd: dateEnd,
      eventSubnet: eventCopy.subnetId,
      numberSwitches: eventCopy.numberSwitches,
      noVlan1: eventCopy.noVlan1 ? 1 : 0,
      comment: eventCopy.comment,
    };
    eventActions.updateEvent(body).then((result) => {
      if (result) {
        setEvent(eventIn);
        setEventCopy(event);
      }
    });
  };

  function onEndEvent(e) {
    e.preventDefault();
    confirm({
      description:
        "Do you really want to end this Event? This will clear the Subnet for future Use!",
    })
      .then(() => {
        eventActions.endEvent(eventIn.id).then((result) => {
          if (result) {
            setEvent(result);
            setEventCopy(result);
          }
        });
      })
      .catch(() => {});
  }

  function onDeleteEvent(e) {
    e.preventDefault();
    confirm({
      description:
        "Do you really want to delete this Event and all its Clients and Vlans??? This can not be undone! ",
    })
      .then(() => {
        eventActions.deleteEvent(eventIn.id).then((result) => {
          if (result) {
            navigate("/events");
          }
        });
      })
      .catch(() => {});
  }

  const handleChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    let event_copy_new = Object.assign({}, eventCopy);
    event_copy_new[key] = value;
    setEventCopy(event_copy_new);
    console.log(event_copy_new);
  };

  const handleDateStartChange = (event) => {
    setDateStart(event.target.value);
    subnetActions
      .getUnusedSubnetList(dateStart, dateEnd, event.id)
      .then((subnetList) => {
        if (subnetList != null) {
          setSubnetList(subnetList);
        }
      });
  };

  const handleDateEndChange = (event) => {
    setDateEnd(event.target.value);
    subnetActions
      .getUnusedSubnetList(dateStart, dateEnd, event.id)
      .then((subnetList) => {
        if (subnetList != null) {
          setSubnetList(subnetList);
        }
      });
  };

  const handleChangeSubnet = (event) => {
    let value = event.target.value;
    let event_copy_new = Object.assign({}, eventCopy);
    event_copy_new["subnetId"] = value;
    setEventCopy(event_copy_new);
    console.log(event_copy_new);
  };

  return (
    <div
      role="tabpanel"
      hidden={activeTab !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {activeTab === index && (
        <Card sx={{ width: "100%" }} elevation={10} color="primary">
          <Box marginTop={2} sx={{ height: "78vh" }}>
            <Grid margin={5}>
              <Card elevation={10} sx={{ backgroundColor: "#333" }}>
                <Box margin={2}>
                  <Grid container spacing={2} marginTop={1} direction="column">
                    <Grid item>
                      <Stack
                        direction="row"
                        spacing={2}
                        fullWidth
                        justifyContent="center"
                      >
                        <TextField
                          name="name"
                          label="Name"
                          type="text"
                          value={eventCopy.name}
                          onChange={handleChange}
                          fullWidth
                          sx={{
                            marginRight: 5,
                            "& .MuiOutlinedInput-root": {
                              "& > fieldset": {
                                borderColor:
                                  event.name === eventCopy.name
                                    ? "white"
                                    : "blue",
                              },
                            },
                            "& .MuiOutlinedInput-root:hover": {
                              "& > fieldset": {
                                borderColor: "orange",
                              },
                            },
                          }}
                        />
                        <TextField
                          label="Technical Contact"
                          type="text"
                          name="technicalContact"
                          value={eventCopy.technicalContact}
                          onChange={handleChange}
                          fullWidth
                          sx={{
                            marginRight: 5,
                            "& .MuiOutlinedInput-root": {
                              "& > fieldset": {
                                borderColor:
                                  event.technicalContact ===
                                  eventCopy.technicalContact
                                    ? "white"
                                    : "blue",
                              },
                            },
                            "& .MuiOutlinedInput-root:hover": {
                              "& > fieldset": {
                                borderColor: "orange",
                              },
                            },
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item fullWidth>
                      <TextField
                        fullWidth
                        label="Description"
                        name="description"
                        value={eventCopy.description}
                        onChange={handleChange}
                        sx={{
                          marginRight: 5,
                          "& .MuiOutlinedInput-root": {
                            "& > fieldset": {
                              borderColor:
                                event.description === eventCopy.description
                                  ? "white"
                                  : "blue",
                            },
                          },
                          "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                              borderColor: "orange",
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid item name="dates">
                      <Stack
                        direction="row"
                        spacing={2}
                        fullWidth
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Stack direction="column" fullWidth>
                          <Typography>Event Start: </Typography>
                          <TextField
                            type="date"
                            value={eventCopy.dateStart}
                            onChange={handleDateStartChange}
                            fullWidth
                            sx={{
                              marginRight: 5,
                              "& .MuiOutlinedInput-root": {
                                "& > fieldset": {
                                  borderColor:
                                    event.dateStart === eventCopy.dateStart
                                      ? "white"
                                      : "blue",
                                },
                              },
                              "& .MuiOutlinedInput-root:hover": {
                                "& > fieldset": {
                                  borderColor: "orange",
                                },
                              },
                            }}
                          />
                        </Stack>
                        {event?.dateEnd === "2999-01-01" ? (
                          <></>
                        ) : (
                          <Stack direction="column" fullWidth>
                            <Typography>Event Ended </Typography>
                            <TextField
                              type="date"
                              value={eventCopy.dateEnd}
                              onChange={handleDateEndChange}
                              fullWidth
                              sx={{
                                marginRight: 5,
                                "& .MuiOutlinedInput-root": {
                                  "& > fieldset": {
                                    borderColor:
                                      event.dateEnd === eventCopy.dateEnd
                                        ? "white"
                                        : "blue",
                                  },
                                },
                                "& .MuiOutlinedInput-root:hover": {
                                  "& > fieldset": {
                                    borderColor: "orange",
                                  },
                                },
                              }}
                            />
                          </Stack>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item>
                      <FormControl
                        fullWidth
                        sx={{
                          marginRight: 5,
                          "& .MuiOutlinedInput-root": {
                            "& > fieldset": {
                              borderColor:
                                event.subnetId === eventCopy.subnetId
                                  ? "white"
                                  : "blue",
                            },
                          },
                          "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                              borderColor: "orange",
                            },
                          },
                        }}
                      >
                        <InputLabel
                          id="demo-simple-select-label"
                          sx={{ color: "#FFF" }}
                        >
                          Subnet
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Subnet"
                          name="subnet"
                          value={eventCopy.subnetId}
                          onChange={(event) => {
                            handleChangeSubnet(event);
                          }}
                        >
                          {subnetList.map((subnet) => (
                            <MenuItem value={subnet.id} key={subnet.id}>
                              {subnet.subnet} / {subnet.subnetMask}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center">
                        <TextField
                          label="Number Switches"
                          type="number"
                          name="numberSwitches"
                          value={eventCopy.numberSwitches}
                          onChange={handleChange}
                          sx={{
                            marginRight: 5,
                            "& .MuiOutlinedInput-root": {
                              "& > fieldset": {
                                borderColor:
                                  event.numberSwitches ===
                                  eventCopy.numberSwitches
                                    ? "white"
                                    : "blue",
                              },
                            },
                            "& .MuiOutlinedInput-root:hover": {
                              "& > fieldset": {
                                borderColor: "orange",
                              },
                            },
                          }}
                        />
                        <Box>
                          <Grid container alignItems="center">
                            <Typography
                              color={
                                event.noVlan1 === eventCopy.noVlan1
                                  ? "white"
                                  : "blue"
                              }
                            >
                              NoVlan1
                            </Typography>
                            <Checkbox
                              value={eventCopy.noVlan1}
                              onClick={handleChange}
                            />
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <TextField
                        fullWidth
                        label="Comment"
                        type="text"
                        name="comment"
                        value={eventCopy.comment}
                        onChange={handleChange}
                        multiline
                        sx={{
                          marginRight: 5,
                          "& .MuiOutlinedInput-root": {
                            "& > fieldset": {
                              borderColor:
                                event.comment === eventCopy.comment
                                  ? "white"
                                  : "blue",
                            },
                          },
                          "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                              borderColor: "orange",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} direction="column">
                    <Grid item>
                      <Box>
                        {apiErrorPostEvent ? (
                          <Box marginTop={1} marginBottom={1}>
                            <AlarmPopup
                              name="fetchError-create Event"
                              atomValue={apiErrorPostEvent}
                              setAtomValue={setApiErrorPostEvent}
                              message={"Error create Event "}
                            />
                          </Box>
                        ) : (
                          <Box />
                        )}
                      </Box>
                    </Grid>
                    <Grid item>
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="center"
                      >
                        <Button variant="outlined" onClick={onCancel}>
                          Cancel
                        </Button>
                        <Button variant="outlined" onClick={onUpdateEvent}>
                          Update Event
                        </Button>
                        <Button
                          variant="outlined"
                          color="warning"
                          onClick={onEndEvent}
                        >
                          End Event
                        </Button>
                        {currentUser?.access_rights === 3 ? (
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={onDeleteEvent}
                          >
                            DELETE EVENT
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          </Box>
        </Card>
      )}
    </div>
  );
}
