import { atom } from "recoil";

const jwtTokenAtom = atom({
  key: "jwtToken",
  // get initial state from local storage to enable user to stay logged in
  default: localStorage.getItem("token"),
});

const currentUserAtom = atom({
  key: "currentUser",
  // get initial state from local storage to enable user to stay logged in
  default: JSON.parse(localStorage.getItem("currentUser")),
});

export { jwtTokenAtom, currentUserAtom };
