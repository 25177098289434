import * as React from "react";
import {
  Box,
  Grid,
  Button,
  Toolbar,
  Tooltip,
  TableContainer,
  IconButton,
  Checkbox,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Stack,
  Card,
  Container,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { alpha } from "@mui/material/styles";
import { useRecoilState } from "recoil";
import { clientListAtom } from "../../State/atoms";

import { useEffect, useState } from "react";
import getRandomInt from "../../_helpers/random_numbers";
import ClientActions from "../../API/clients";

export default function ClientTable(props) {
  const { event, vlan } = props;
  const [clients, setClients] = useRecoilState(clientListAtom);

  const [changesActive, setChangesActive] = useState(false);

  const [selected, setSelected] = React.useState([]);

  const clientActions = ClientActions();

  useEffect(() => {
    window.addEventListener("paste", handlePaste);
    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, []);

  useEffect(() => {
    clientActions.getClientList(event.id, vlan.id).then();
    setSelected([]);
    setChangesActive(false);
  }, [event, vlan]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = clients.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, client) => {
    const selectedIndex = selected.indexOf(client);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, client);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  function handlePaste(e) {
    e.preventDefault();

    let paste = (
      e?.clipboardData ||
      e?.originalEvent?.clipboardData ||
      window?.clipboardData
    ).getData("text");

    let addingClients = [];

    let rows = paste.split("\n");

    rows.forEach((row) => {
      const fields = row.split("\t");
      if (fields.length != 3) {
        console.log("error - Array not suitable");
      } else {
        setChangesActive(true);
        let client = {
          id: getRandomInt() * -1,
          name: fields[0],
          address: fields[1],
          comment: fields[2],
          eventId: event.id,
          vlanId: vlan.id,
          edit: "true",
          new: "true",
        };
        addingClients.push(client);
      }
    });

    console.log("addingClients Array in handleClick:");
    console.log(addingClients);
    console.log(" ");
    if (addingClients.length > 0) {
      setClients((old_clients) => [...old_clients, ...addingClients]);
    }
  }

  const handleChangeClient = (index_clients, value, key, client) => {
    let newClient = { [key]: value, edit: "true" };
    newClient = Object.assign({}, clients[index_clients], newClient);
    let clients_arr = [...clients];
    clients_arr[index_clients] = newClient;
    setClients(clients_arr);
    setChangesActive(true);
  };

  function handleDeclineChanges(e) {
    e.preventDefault();
    clientActions.getClientList(event.id, vlan.id).then();
    setChangesActive(false);
  }

  function handleApplyChanges(e) {
    e.preventDefault();

    var new_Clients = clients.filter(
      (client) => isEdit(client) & isNew(client)
    );
    var edited_Clients = clients.filter(
      (client) => isEdit(client) & !isNew(client)
    );
    create_Clients(new_Clients);
    update_Clients(edited_Clients);
    setChangesActive(false);
  }

  function create_Clients(new_Clients) {
    var client_Buffer = [...clients];
    clientActions.createClients(new_Clients).then((results) => {
      console.log("Client create API call result: ");
      console.log(results);
      console.log(" ");

      results.forEach((result, i) => {
        //find entry in clientList:
        let index = client_Buffer.findIndex(
          (element) => element.id === new_Clients[i].id
        );
        console.log("index of result in existing array = " + index);
        if (result.success === true) {
          updateClientInList(client_Buffer, index, "false", null, result.id);
        } else {
          updateClientInList(
            client_Buffer,
            index,
            "error",
            result.error,
            clients[i].id
          );
        }
      });
      console.log("new client_List:");
      console.log(client_Buffer);
      console.log(" ");

      setClients(client_Buffer);

      console.log("updated clients State object:");
      console.log(clients);
      console.log(" ");
    });
  }

  function update_Clients(edited_Clients) {
    let client_Buffer = [...clients];

    clientActions.updateClients(edited_Clients).then((results) => {
      console.log(results);
      results.forEach((result, i) => {
        //find entry in clientList:
        let index = client_Buffer.findIndex(
          (element) => element.id === edited_Clients[i].id
        );
        console.log(index);
        if (result.success === true) {
          updateClientInList(
            client_Buffer,
            index,
            "false",
            null,
            result.id
          ).then();
        } else {
          updateClientInList(
            client_Buffer,
            index,
            "error",
            result.error
          ).then();
        }
      });
    });
    setClients(client_Buffer);
  }

  //Set clients edit-state in Clients-List
  function updateClientInList(
    client_list,
    index_clients,
    editValue,
    errorMessage,
    newId
  ) {
    let newClient = { edit: editValue, errorMessage: errorMessage };
    errorMessage == null ?? delete newClient.errorMessage;
    newId ?? (newClient.id = newId);
    newClient = Object.assign({}, client_list[index_clients], newClient);
    console.log(newClient);
    client_list[index_clients] = newClient;
  }

  function handleAddClient(e) {
    e.preventDefault();
    let newClient = {
      id: getRandomInt() * -1,
      new: "true",
      edit: "true",
      eventId: event.id,
      vlanId: vlan.id,
      address: vlan.subnet,
      name: "",
      comment: "",
    };
    setClients(clients.concat(newClient));
    setChangesActive(true);
  }

  function handleDeleteClients(e, clients) {
    e.preventDefault();
    console.log(clients);
    clientActions.deleteClients(clients).then((results) => {
      console.log(results);
      clientActions.getClientList(event.id, vlan.id).then();
      setSelected([]);
    });
  }

  function isEdit(obj) {
    if (obj.edit === "true") {
      return true;
    } else {
      return false;
    }
  }

  function isNew(obj) {
    if (obj.new === "true") {
      return true;
    } else {
      return false;
    }
  }

  const isSelected = (client) => selected.indexOf(client) !== -1;

  /*  const handleChangeClient = (i, value, key, client) => {
    let newClient = { [key]: value, edit: "true" };
    newClient = Object.assign({}, client, newClient);
    let clients_arr = [...clients];
    clients_arr[i] = newClient;
    setCients(clients_arr);
    console.log(clients);
  };
*/

  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            //            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            //            component="div"
          >
            Clients
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <Button
              startIcon={<DeleteIcon />}
              variant="contained"
              onClick={(event) => handleDeleteClients(event, selected)}
              color="error"
            >
              Delete
            </Button>
          </Tooltip>
        ) : (
          <Grid
            spacing={1}
            container
            marginTop="2px"
            marginBottom="5px"
            justifyContent="flex-end"
          >
            <Grid item>
              <Button
                startIcon={<AddCircleIcon />}
                variant="outlined"
                onClick={handleAddClient}
              >
                Add
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={<ContentPasteIcon />}
                variant="outlined"
                onClick={handlePaste}
                disabled
              >
                Paste
              </Button>
            </Grid>
          </Grid>
        )}
      </Toolbar>
    );
  };

  return (
    <Card elevation={10} sx={{ backgroundColor: "#333" }}>
      <Container>
        <Grid item>
          <Box>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table
                elevation={10}
                size="small"
                sx={{
                  backgroundColor: "#3C3C3C",
                }}
              >
                <TableHead sx={{ backgroundColor: "#333" }}>
                  <TableRow sx={{ "& > *": { borderBottomColor: "#000" } }}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < clients.length
                        }
                        checked={
                          clients.length > 0 &&
                          selected.length === clients.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all clients",
                        }}
                      />
                    </TableCell>
                    <TableCell>Name </TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Comment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clients.map((client, i) => {
                    const isItemSelected = isSelected(client);
                    const labelId = `enhanced-table-checkbox-${i}`;
                    return (
                      <>
                        <TableRow
                          //onClick={(event) => handleClick(event, client.address)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                          hover
                          key={i}
                          sx={{
                            ...(!isEdit(client) && {
                              border: 1,
                              borderColor: null,
                            }),
                            ...(isEdit(client) && {
                              border: 1,
                              borderColor: "#e8b202",
                            }),
                            ...(client.edit === "error" && {
                              border: 2,
                              borderColor: "#e81202",
                            }),
                          }}
                        >
                          <TableCell
                            style={{ borderBottom: "none" }}
                            padding="checkbox"
                          >
                            <Checkbox
                              color="primary"
                              onClick={(event) => handleClick(event, client)}
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell style={{ borderBottom: "none" }}>
                            <Input
                              value={client.name}
                              onChange={(event) =>
                                handleChangeClient(
                                  i,
                                  event.target.value,
                                  "name",
                                  client
                                )
                              }
                            ></Input>
                          </TableCell>
                          <TableCell style={{ borderBottom: "none" }}>
                            <Input
                              value={client.address}
                              onChange={(event) =>
                                handleChangeClient(
                                  i,
                                  event.target.value,
                                  "address",
                                  client
                                )
                              }
                            ></Input>
                          </TableCell>
                          <TableCell style={{ borderBottom: "none" }}>
                            <Input
                              value={client.comment}
                              onChange={(event) =>
                                handleChangeClient(
                                  i,
                                  event.target.value,
                                  "comment",
                                  client
                                )
                              }
                            ></Input>
                          </TableCell>
                        </TableRow>
                        {client?.errorMessage?.length > 0 ? (
                          <TableRow key={getRandomInt()}>
                            <TableCell
                              style={{
                                paddingBottom: 0,
                                paddingTop: 0,
                                borderBottom: "none",
                              }}
                              colSpan={3}
                            >
                              <Typography color={"red"}>
                                {client.errorMessage}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <Box></Box>
                        )}
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item marginTop={5}>
          <Box name="formActions" marginBottom={1}>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="primary"
                elevation="20"
                startIcon={<CheckIcon />}
                sx={{ backgroundColor: "green" }}
                onClick={handleApplyChanges}
                disabled={!changesActive}
              >
                Apply Changes
              </Button>

              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleDeclineChanges}
                disabled={!changesActive}
              >
                Discard Changes
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Container>
    </Card>
  );
}
