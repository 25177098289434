import * as React from "react";
import {
  Box,
  Button,
  Grid,
  TableContainer,
  Collapse,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import SubnetActions from "../../API/subnets";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { subnetListAtom } from "../../State/atoms";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function SubnetTable() {
  const [subnetList, setSubnetList] = useRecoilState(subnetListAtom);
  const [rowExpanded, setRowExpanded] = useState(false);
  const subnetActions = SubnetActions();

  useEffect(() => {
    subnetActions.getSubnets();
  }, []);

  const passedSetRowExpanded = (e, value) => {
    e.preventDefault();
    setRowExpanded(value);
    console.log(value);
  };

  function CustomTableHeader() {
    return (
      <TableHead style={{ backgroundColor: "#111" }}>
        <TableRow>
          <TableCell />
          <TableCell>ID</TableCell>
          <TableCell align="left">Subnet</TableCell>
          <TableCell align="left">Net-Mask</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function CollapsableRow(props) {
    const { subnet, setRowExpanded } = props;
    const [open, setOpen] = useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { border: 0 } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(e) => {
                setOpen(!open);
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{subnet.id}</TableCell>
          <TableCell align="left">{subnet.subnet}</TableCell>
          <TableCell align="left">{subnet.subnetMask}</TableCell>
        </TableRow>
        <TableRow sx={{ "& > *": { borderBottomColor: "#000" } }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="body1" gutterBottom component="div">
                  Sub
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="collapsible table">
        <CustomTableHeader></CustomTableHeader>
        <TableBody>
          {subnetList.map((subnet) => (
            <CollapsableRow
              key={subnet.id}
              subnet={subnet}
              setRowExpanded={passedSetRowExpanded}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
