import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRecoilValue } from "recoil";
import { Navigate, Outlet } from "react-router-dom";
import React, { useState } from "react";
import { history } from "../../_helpers/history_helper";

import {
  Container,
  Card,
  Typography,
  Grid,
  ListItem,
  Box,
  TextField,
  Button,
} from "@mui/material";

import { jwtTokenAtom } from "../../State/auth";
import { useUserActions } from "../../API/user.actions";

export { Login };

function Login({}) {
  const token = useRecoilValue(jwtTokenAtom);
  const userActions = useUserActions();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    // redirect to home if already logged in
    if (token) {
      history.push("/events");
      console.log("pushed route");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // form validation rules
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, setError, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const sx_texfield_color = {
    marginRight: 5,
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        borderColor: "white",
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      "& > fieldset": {
        borderColor: "orange",
      },
    },
  };

  function onSubmit() {
    console.log(username);
    return userActions
      .login(username, password)
      .then(() => {
        history.push("/events");
      })
      .catch((error) => {
        setError("apiError", { message: error });
      });
  }

  return token ? (
    <Navigate to="/events" />
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      justify="center"
      minHeight="100%"
    >
      <Box>
        <Card>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "300px",
              minWidth: "300px",
            }}
          >
            <Grid container direction="column" alignItems="flex-start">
              <Grid item>
                <Typography
                  variant="h4"
                  style={{ marginLeft: "10px", marginTop: "20px" }}
                >
                  Login
                </Typography>
              </Grid>
              <Grid item>
                <div className="form-group">
                  <TextField
                    required
                    style={{ margin: "15px" }}
                    label="Username"
                    variant="outlined"
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    sx={sx_texfield_color}
                  ></TextField>
                  <div className="invalid-feedback">
                    {errors.username?.message}
                  </div>
                </div>
              </Grid>
              <Grid item>
                {" "}
                <div className="form-group">
                  <TextField
                    required
                    style={{ margin: "15px" }}
                    label="Password"
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        onSubmit();
                      }
                    }}
                    sx={sx_texfield_color}
                  ></TextField>
                  <div className="invalid-feedback">
                    {errors.password?.message}
                  </div>
                </div>
              </Grid>
              <Grid item>
                <Button
                  disabled={isSubmitting}
                  onClick={onSubmit}
                  type="button"
                  color="primary"
                  variant="outlined"
                  style={{ margin: "20px" }}
                  {...(isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  ))}
                >
                  Login
                </Button>
                {errors.apiError && (
                  <div className="alert alert-danger mt-3 mb-0">
                    {errors.apiError?.message}
                  </div>
                )}
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </Box>
  );
}

/*
<div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label>Username</label>
                <input
                  name="username"
                  type="text"
                  {...register("username")}
                  className={`form-control ${
                    errors.username ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.username?.message}
                </div>
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  name="password"
                  type="password"
                  {...register("password")}
                  className={`form-control ${
                    errors.password ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.password?.message}
                </div>
              </div>
              <button disabled={isSubmitting} className="btn btn-primary">
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Login
              </button>
              {errors.apiError && (
                <div className="alert alert-danger mt-3 mb-0">
                  {errors.apiError?.message}
                </div>
              )}
            </form>
          </div> */
