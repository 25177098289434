import {
  Button,
  Box,
  Grid,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  Typography,
  Container,
} from "@mui/material";
import AlarmPopup from "../Footer/alarm_popup";
import React from "react";
import { useRecoilState } from "recoil";

import { useState } from "react";
import SubnetActions from "../../API/subnets";
import { apiErrorPostSubnetAtom } from "../../State/errors";

export default function AddSubnetDialog(props) {
  const { handleCloseDialog, openDialog } = props;
  const subnetActions = SubnetActions();

  const [subnet, setSubnet] = useState("");
  const [mask, setMask] = useState("");

  const [apiErrorPostSubnet, setApiErrorPostSubnet] = useRecoilState(
    apiErrorPostSubnetAtom
  );

  const sx_texfield_color = {
    marginRight: 5,
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        borderColor: "white",
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      "& > fieldset": {
        borderColor: "orange",
      },
    },
  };

  const onCancel = () => {
    handleCloseDialog();
  };

  const onAddEvent = () => {
    const body = {
      subnet: subnet,
      subnetMask: mask,
    };

    subnetActions.createSubnet(body).then((result) => {
      if (result) {
        handleCloseDialog();
      }
    });
  };

  const handleSubnetChange = (event) => {
    setSubnet(event.target.value);
  };

  const handleMaskChange = (event) => {
    setMask(event.target.value);
  };

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>Add Subnet</DialogTitle>
      <DialogContent>
        <Container>
          <Grid fullWidth container spacing={2} direction="column">
            <Grid item>
              <Grid>
                <TextField
                  label="Subnet"
                  type="text"
                  defaultValue=""
                  onChange={handleSubnetChange}
                  color="primary"
                  sx={sx_texfield_color}
                />
                <TextField
                  label="Subnet-Mask"
                  type="text"
                  onChange={handleMaskChange}
                  sx={sx_texfield_color}
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
        {apiErrorPostSubnet ? (
          <Box marginTop={1} marginBottom={1}>
            <AlarmPopup
              name="fetchError-create Subnet"
              atomValue={apiErrorPostSubnet}
              setAtomValue={setApiErrorPostSubnet}
              message={"Error create Subnet "}
            />
          </Box>
        ) : (
          <Box />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onAddEvent}>Add Subnet</Button>
      </DialogActions>
    </Dialog>
  );
}
