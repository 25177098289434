import { useSetRecoilState } from "recoil";
import { useFetchWrapper } from "../_helpers/fetch-wrapper";
import { eventListAtom } from "../State/atoms";
import { API_URL } from "../config.js";

import {
  fetchErrorEventListAtom,
  fetchErrorEventAtom,
  apiErrorPostEventAtom,
  apiErrorDeleteEventAtom,
} from "../State/errors";

export default function EventActions() {
  //const baseUrl = `${process.env.REACT_APP_API_URL}/users`;
  const baseUrl = API_URL + "/events";

  const fetchWrapper = useFetchWrapper();
  const setEventList = useSetRecoilState(eventListAtom);
  const setFetchErrorEventList = useSetRecoilState(fetchErrorEventListAtom);
  const setFetchErrorEvent = useSetRecoilState(fetchErrorEventAtom);
  const setApiErrorPostEvent = useSetRecoilState(apiErrorPostEventAtom);
  const setApiErrorDeleteEvent = useSetRecoilState(apiErrorDeleteEventAtom);

  return {
    getEventList,
    getEvent,
    createEvent,
    deleteEvent,
    updateEvent,
    endEvent,
  };

  function getEventList() {
    return fetchWrapper
      .get(`${baseUrl}/list`)
      .then(setEventList)
      .catch((err) => {
        console.log(err);
        setFetchErrorEventList(err.message);
      });
  }

  function getEvent(id) {
    //console.log("fetching Event-List");
    return fetchWrapper
      .get(`${baseUrl}/${id}`)
      .then()
      .catch((err) => {
        console.log(err);
        getEventList();
        setFetchErrorEvent(err.message);
      });
  }

  function createEvent(event) {
    console.log(`Post Event create with name: ${event.name}`);
    return fetchWrapper.post(`${baseUrl}/create`, event).catch((err) => {
      setApiErrorPostEvent(err);
    });
  }

  function updateEvent(event) {
    console.log(`Update Event create with name: ${event.name}`);
    return fetchWrapper.post(`${baseUrl}/update`, event).catch((err) => {
      setApiErrorPostEvent(err);
    });
  }

  function endEvent(eventId) {
    console.log(`End Event with name: ${eventId.name}`);
    return fetchWrapper
      .post(`${baseUrl}/end?eventId=${eventId}`)
      .catch((err) => {
        setApiErrorPostEvent(err);
      });
  }

  function deleteEvent(Event_id) {
    console.log(`Delete Event ${Event_id}`);
    return fetchWrapper
      .delete(`${baseUrl}/${Event_id}`)
      .then()
      .catch((err) => {
        setApiErrorDeleteEvent(err);
      });
  }
}

//throw new Error(err.message);
