import {
  Box,
  Card,
  IconButton,
  Typography,
  Toolbar,
  Grid,
  Button,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import ClientTable from "./ClientTable";
import { useRecoilValue } from "recoil";
import { Router, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import VlanActions from "../../API/vlan";
import ClientActions from "../../API/clients";
import { clientListAtom } from "../../State/atoms";
import { currentUserAtom } from "../../State/auth";
import { CircularIndeterminate } from "../../_helpers/loading-Icon";
import getRandomInt from "../../_helpers/random_numbers";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";

export default function VlanDetail(props) {
  let { vlanId } = useParams();
  const event = props.event;

  const [vlan, setVlan] = useState();
  const vlanActions = VlanActions();
  const [changesActive, setChangesActive] = useState(false);
  const [vlanCopy, setVlanCopy] = useState();
  const currentUser = useRecoilValue(currentUserAtom);
  const navigate = useNavigate();
  const confirm = useConfirm();

  useEffect(() => {
    vlanActions.getVlan(vlanId).then((vlan) => {
      setVlan(vlan);
      setVlanCopy(vlan);
    });
    setChangesActive(false);
  }, [vlanId]);

  const handleChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setChangesActive(true);

    let vlan_copy_new = Object.assign({}, vlanCopy);
    vlan_copy_new[key] = value;
    setVlanCopy(vlan_copy_new);
    console.log(vlan_copy_new);
  };

  function handleDeclineChanges(e) {
    e.preventDefault();
    setVlanCopy(vlan);
    setChangesActive(false);
  }

  function onDeleteVlan(e) {
    e.preventDefault();
    confirm({
      description:
        "Do you really want to delete this Vlan and all its Clients and ??? This can not be undone! ",
    })
      .then(() => {
        vlanActions.deleteVlan(vlan.id).then((result) => {
          if (result) {
            vlanActions.getVlanList(vlan.eventId);
            navigate(`/events/${vlan.eventId}`);
          }
        });
      })
      .catch(() => {});
  }

  function handleApplyChanges(e) {
    e.preventDefault();

    const body = {
      id: vlanCopy.id,
      eventId: vlanCopy.eventId,
      vlanId: vlanCopy.vlanId,
      name: vlanCopy.name,
      subnet: vlanCopy.subnet,
      subnetMask: vlanCopy.subnetMask,
      dhcpEnable: vlanCopy.dhcpEnable,
      dhcpRange: vlanCopy.dhcpRange,
      startIp: vlanCopy.startIp,
      ipIGMP: vlanCopy.ipIGMP,
      taggedPorts: vlanCopy.taggedPorts,
      untaggedPorts: vlanCopy.untaggedPorts,
    };
    vlanActions.updateVlan(body).then((result) => {
      if (result) {
        vlanActions.getVlanList(vlan.eventId);
        vlanActions.getVlan(vlanId).then((vlan) => {
          setVlan(vlan);
          setVlanCopy(vlan);
        });
        setChangesActive(false);
      }
    });
  }

  return (
    <Card sx={{ width: "100%", height: "100%" }} elevation={10} color="primary">
      {!vlan && !vlanCopy ? (
        <CircularIndeterminate />
      ) : (
        <Grid
          height="100%"
          container
          direction="column"
          justifyContent="flex-start"
        >
          <Grid item margin={"10px"}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5">
                Vlan {vlan.vlanId}: {vlan.name}
              </Typography>
              {currentUser?.access_rights === 3 ? (
                <Tooltip title="Delete Vlan">
                  <IconButton sx={{ color: "#fff" }} onClick={onDeleteVlan}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )}
            </Stack>
          </Grid>

          <Grid item name="details" margin={"10px"}>
            <Card elevation={10} sx={{ backgroundColor: "#333" }}>
              <Box marginTop={1}>
                <Toolbar
                  sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                  }}
                >
                  <Typography
                    sx={{ flex: "1 1 100%" }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                  >
                    Details
                  </Typography>
                </Toolbar>

                <Grid margin={2} spacing={2} container direction="row">
                  <Grid item>
                    <TextField
                      label="Vlan-Name"
                      value={vlanCopy?.name}
                      name="name"
                      onChange={handleChange}
                      sx={{
                        marginRight: 5,
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": {
                            borderColor:
                              vlan?.name === vlanCopy?.name ? "white" : "blue",
                          },
                        },
                        "& .MuiOutlinedInput-root:hover": {
                          "& > fieldset": {
                            borderColor: "orange",
                          },
                        },
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Vlan-Tag"
                      value={vlanCopy?.vlanId}
                      name="vlanId"
                      onChange={handleChange}
                      sx={{
                        marginRight: 5,
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": {
                            borderColor:
                              vlan?.vlanId === vlanCopy?.vlanId
                                ? "white"
                                : "blue",
                          },
                        },
                        "& .MuiOutlinedInput-root:hover": {
                          "& > fieldset": {
                            borderColor: "orange",
                          },
                        },
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Vlan-Subnet"
                      value={vlanCopy?.subnet}
                      name="subnet"
                      onChange={handleChange}
                      sx={{
                        marginRight: 5,
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": {
                            borderColor:
                              vlan?.subnet === vlanCopy?.subnet
                                ? "white"
                                : "blue",
                          },
                        },
                        "& .MuiOutlinedInput-root:hover": {
                          "& > fieldset": {
                            borderColor: "orange",
                          },
                        },
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Vlan-SubnetMask"
                      value={vlan?.subnetMask}
                      name="subnetMask"
                      onChange={handleChange}
                      sx={{
                        marginRight: 5,
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": {
                            borderColor:
                              vlan?.subnetMask === vlanCopy?.subnetMask
                                ? "white"
                                : "blue",
                          },
                        },
                        "& .MuiOutlinedInput-root:hover": {
                          "& > fieldset": {
                            borderColor: "orange",
                          },
                        },
                      }}
                    ></TextField>
                  </Grid>
                </Grid>
              </Box>
              {changesActive ? (
                <Box name="formActions" marginBottom={1}>
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      elevation="20"
                      startIcon={<CheckIcon />}
                      sx={{ backgroundColor: "green" }}
                      onClick={handleApplyChanges}
                      disabled={!changesActive}
                    >
                      Apply Changes
                    </Button>

                    <Button
                      variant="contained"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={handleDeclineChanges}
                      disabled={!changesActive}
                    >
                      Discard Changes
                    </Button>
                  </Stack>
                </Box>
              ) : (
                <></>
              )}
            </Card>
          </Grid>
          <Grid item name="clientTable" margin={"10px"}>
            <ClientTable vlan={vlan} event={event} />
          </Grid>
        </Grid>
      )}
    </Card>
  );
}
