import { useRecoilState } from "recoil";

import AlarmPopup from "./alarm_popup";

import { Box, Stack } from "@mui/material";
import {
  fetchErrorEventListAtom,
  fetchErrorEventAtom,
  apiErrorPostSubnetAtom,
  fetchErrorSubnetListAtom,
} from "../../State/errors";

function ErrorFooter() {
  const [fetchErrorEventList, setFetchErrorEventList] = useRecoilState(
    fetchErrorEventListAtom
  );
  const [fetchErrorEvent, setFetchErrorEvent] =
    useRecoilState(fetchErrorEventAtom);

  const [fetchErrorSubnetList, setFetchErrorSubnetList] = useRecoilState(
    fetchErrorSubnetListAtom
  );
  const [apiErrorPostSubnet, setApiErrorPostSubnet] = useRecoilState(
    apiErrorPostSubnetAtom
  );

  return (
    <Box marginTop="10px" position="absolute" bottom="2px">
      <Stack spacing={1}>
        <AlarmPopup
          name="fetchError-EventList"
          atomValue={fetchErrorEventList}
          setAtomValue={setFetchErrorEventList}
          message={"Error fetching EventList "}
        />

        <AlarmPopup
          name="fetchError-Event"
          atomValue={fetchErrorEvent}
          setAtomValue={setFetchErrorEvent}
          message={"Error fetching Event "}
        />

        <AlarmPopup
          name="fetchError-Subnet List"
          atomValue={fetchErrorSubnetList}
          setAtomValue={setFetchErrorSubnetList}
          message={"Error fetching EventList "}
        />
      </Stack>
    </Box>
  );
}

export default ErrorFooter;
